<template>
    <v-dialog max-width="500" scrollable v-model="dialog" persistent>
        <template v-slot:activator="{ on: menu, attrs }">
            <v-btn text v-bind="attrs" v-on="{ ...menu }" color="grey">
                <v-icon left>mdi-tune</v-icon>
                Filter
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                Filtrer par
                <v-spacer />
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-4">
                <v-row>
                    <v-col cols="12">
                        <v-select prepend-icon="mdi-filter-outline" multiple chips deletable-chips small-chips dense
                            clearable :items="services" v-model="filter.selectedServices" :item-text="'name'"
                            :item-value="'id'" label="Services..."></v-select>
                        
                            <v-text-field v-model="filter.username"
                          prepend-icon="mdi-filter-outline"
                          append-icon="mdi-account"
                          type="text"
                          dense clearable
                          label="Nom utilsateur, E-mail, Téléphone.." ></v-text-field>
                    </v-col>

                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" :disabled="isDisabled" depressed block @click="handleFilter">
                    Recherche
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import { HTTP } from '@/http-common'
export default {
    props: ['filter'],
    data() {
        return {
            dialog: false,
            services: []
        }
    },
    methods: {
        handleFilter() {
            this.dialog = false
            this.$emit('filter', this.filter)
        },
        clearFilter() {
            this.props.filter = {}
        },
        getServices() {
            this.loadingService = true;
            HTTP.get("/service?size=1000", { params: {} }).then((res) => {
                this.services = res.data.data;
                this.loadingService = false;
            }).catch((err) => {
                this.loadingService = false;
                console.log(err);
            });
        },
    },
    computed: {
        isDisabled() {
            return Object.values(this.filter).every(x => x === null || x === '' || x.length === 0)
        },
    },
    mounted(){
        this.getServices();
    }
}
</script>
  
  
<style scoped>
.no-anim:hover {
    transform: none;
}
</style>