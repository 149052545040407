<template>
    <v-container fluid>
        <div class="main">
            <v-row v-if="loading">
                <v-col cols="12">
                    <v-card class="rounded-lg" flat>
                        <v-skeleton-loader type="table"></v-skeleton-loader>
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-else>

                <v-col cols="12">

                    <v-card class="rounded-lg shadow">
                        <v-card-title class="d-flex justify-space-between ">
                            <div class="d-flex">
                            <form @submit.prevent="fetchData">
                                <v-text-field
                                    v-model="filter.keyword"
                                    append-icon="mdi-magnify"
                                    clearable
                                    dense
                                    filled
                                    hide-details
                                    placeholder="Rechercher..."
                                    rounded
                                    single-line
                                />
                                </form>
                            <v-btn class="rounded-lg ml-2" color="gifty" dark depressed @click="addCommission">
                                <v-icon left>mdi-plus</v-icon>
                                Nouvelle commission
                            </v-btn>
                        </div>

                            <div>
                                

                                <FilterDialog @filter="fetchData" :filter="filter" />

                                <v-btn class="rounded-lg ml-2" color="" depressed @click="refresh">
                                    <v-icon left>mdi-refresh</v-icon>
                                    Actualiser
                                </v-btn>

                            </div>
                        </v-card-title>

                        <v-divider />

                        <v-card-text>
                            <div v-if="commissions.data.length > 0">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Utilisateur</th>
                                                <th>Téléphone</th>
                                                <th>Commission </th>
                                                <th>Service </th>
                                                <th>Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in commissions.data" :key="item.id">

                                                <td>
                                                    {{ item.id }}
                                                </td>

                                                <td style="cursor: pointer;" @click="showAccount(item?.user?.account?.id)">
                                                    {{ item?.user?.name }}
                                                </td>
                                                <td >
                                                    {{ item?.user?.phone }}
                                                </td>

                                                <td class="text-no-wrap font-weight-medium gifty--text">
                                                    {{ item.commission }} %
                                                </td>

                                                <td class="d-flex align-center">
                                                    <v-img :src="getImageLink(item?.service?.image)" max-width="40"
                                                        max-height="40" class="mr-2" />
                                                    {{ item?.service?.name }}
                                                </td>


                                                <td class="text-no-wrap">
                                                    <v-icon small :style="{ marginTop: '-3px' }"
                                                        color="gifty">mdi-clock-outline
                                                    </v-icon>
                                                    {{ moment(item.created_at).format('DD/MM/YYYY hh:mm') }}
                                                </td>


                                                <td>
                                                    <v-menu transition="slide-x-transition" bottom right>

                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon v-bind="attrs" v-on="on">
                                                                <v-icon>mdi-dots-vertical</v-icon>
                                                            </v-btn>
                                                        </template>

                                                        <v-list dense>

                                                            <v-list-item @click="updateCommission(item)">
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-pencil-outline</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-title>Modifier</v-list-item-title>
                                                            </v-list-item>

                                                            <v-list-item @click="handleDelete(item)">
                                                                <v-list-item-icon>
                                                                    <v-icon>mdi-delete-outline</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-title>Supprimer</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>

                                                    </v-menu>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                                <v-divider />

                                <div class="d-flex justify-space-between pl-4 pt-6 pr-4">

                                    <div :style="{ width: '80px' }">
                                        <v-select outlined v-model="filter.size" dense :items="[5, 10, 20, 30, 40, 50]"
                                            @change="fetchData"></v-select>
                                    </div>

                                    <v-spacer />

                                    <v-pagination total-visible="6" v-if="commissions.total > 15" circle
                                        v-model="filter.page" :length="Math.ceil(commissions.total / filter.size)"
                                        @input="fetchData"></v-pagination>

                                </div>

                            </div>

                            <div v-else class="text-center">
                                <v-avatar tile size="200">
                                    <v-img :src="require('@/assets/database.svg')"></v-img>


                                </v-avatar>
                                <p>Aucun enregistrement</p>
                            </div>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <ConfirmDelete ref="deleteCommission" @refresh="fetchData" />
            <AddCommissionServiceDialog ref="AddCommissionServiceDialog" @refresh="fetchData" />
            <UpdateCommissionDialog ref="updateCommissionDialog" @refresh="fetchData" />
        </div>
    </v-container>
</template>

<script>
import { HTTP } from '@/http-common';
import ConfirmDelete from './ConfirmDelete.vue';
import AddCommissionServiceDialog from "./AddCommissionServiceDialog.vue";
import UpdateCommissionDialog from "./UpdateCommissionDialog.vue";

import FilterDialog from './FilterDialog.vue';

export default {
    components: { ConfirmDelete, AddCommissionServiceDialog, UpdateCommissionDialog, FilterDialog },
    data() {
        return {
            commissions: {},
            loading: true,
            filter: {
                page: 1,
                size: 10,
                selectedServices: [],
                username: ""
            }
        }
    },
    methods: {
        fetchData() {
            this.loading = true;
            HTTP.get("v1/commissions", {
                params: {
                    ...this.filter
                }
            }).then((res) => {
                this.commissions = res.data.data;
                this.loading = false;

            }).catch((e) => {
                this.loading = false;
                console.log(e);
            });
        },
        refresh() {
            this.filter = {
                page: 1,
                size: 10,
                selectedServices: [],
                username: ""
            }
            this.fetchData();
        },
        handleDelete(item) {
            this.$refs.deleteCommission.open(item);
        },
        showAccount(account_id) {
            let route = this.$router.resolve({ path: "/users/" + account_id });
            window.open(route.href, "_blank");
        },
        addCommission() {
            this.$refs.AddCommissionServiceDialog.open();
        },
        updateCommission(item) {
            this.$refs.updateCommissionDialog.open(item);
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style scoped></style>