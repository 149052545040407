<template>
    <div>
        <v-dialog max-width="500" scrollable v-model="dialog" persistent>
            <v-card>
                <v-progress-linear v-if="isLoading" color="primary" indeterminate reverse></v-progress-linear>
                <v-card-title>
                    <v-icon left>mdi-tune</v-icon>
                    Affecter une commission
                    <v-spacer />
                    <v-btn small outlined text class="mr-2 ml-2 no-anim" @click="clearForm">Effacer</v-btn>
                    <v-icon @click="dialog = false">mdi-close</v-icon>
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-4">
                    <v-row>
                        <v-col cols="12" v-if="error">
                            <v-alert border="left" color="error" dark>
                                {{ error }}
                                <ul v-if="errors">
                                    <li v-for="(value, key, index) in errors" :key="`${key}-${index}`">
                                        {{ value[0] }}
                                    </li>
                                </ul>
                            </v-alert>
                        </v-col>

                        <v-col cols="12">
                            <v-select prepend-icon="mdi-card" chips deletable-chips small-chips dense clearable
                                :items="services" v-model="form.service_id" :item-text="'name'" :item-value="'id'"
                                label="Service...">
                                <template v-slot:item="slotProps">
                                    <div style="width: 50px">
                                        <img :src="getImageLink(slotProps.item.image)" max-height="25" max-width="25"
                                            width="25" height="25" class="p-2" />
                                    </div>

                                    {{ slotProps.item.name }}
                                </template>
                            </v-select>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field type="number" label="Commission *" prepend-icon="mdi-percent " @input="
                                errors && errors.commission
                                    ? (errors.commission = '')
                                    : false
                                " :error-messages="errors && errors.commission ? errors.commission[0] : ''
        " v-model="form.commission" />
                        </v-col>

                        <v-col>
                            <v-btn v-if="!form.is_default" class="secondary rounded-lg mt-3" large depressed
                                @click="selectUsers">
                                Affecter des Utilisateurs ({{ form?.userIds?.length }})
                            </v-btn>
                            <p v-if="errors && errors.userIds" class="text-danger mt-0">
                                {{ errors.userIds[0] }}
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="primary" :disabled="isDisabled()" depressed block @click="handleStore">
                        Sauvegarder
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <SelectUsers ref="selectUsers" @selected="setUsers($event)" />
    </div>
</template>
  
<script>
import { HTTP } from "@/http-common";

import SelectUsers from "../publicities/components/SelectUsers.vue";

export default {
    components: { SelectUsers },
    data() {
        return {
            error: "",
            btnLoading: false,
            dialog: false,
            isLoading: false,
            errors: [],
            form: {
                commission: 0,
                service_id: null,
                userIds: [],
            },

        };
    },

    methods: {
        open() {
            this.form = {
                commission: 0,
                service_id: null,
                userIds: [],
            };
            
            this.dialog = true;
        },
        isDisabled() {
            return (
                Object.values(this.form).every(
                    (x) => x === null || x === "" || x.length === 0
                ) || this.isLoading
            );
        },
        handleStore() {
            this.isLoading = true;
            this.errors = [];
            this.err = "";
            HTTP.post("/v1/commissions/users", this.form)
                .then((res) => {
                    console.log(res.data);

                    this.isLoading = false;
                    this.dialog = false;
                    this.$emit("refresh");
                })
                .catch((err) => {
                    this.isLoading = false;

                    this.error = err.response.data.message;
                    this.errors = err.response.data.errors;
                });
        },
        clearForm() {
            this.form.service_id = null;
            this.form.commission = 0;
        },
        setUsers(e) {
            this.form.userIds = e.users;
        },
        selectUsers() {
            this.$refs.selectUsers.open();
        },
    },

    computed: {
        services() {
            return this.$store.getters.getServices.data;
        },
    },
};
</script>